<template>
    <div class="contenter" style="height:100%;padding-top:88px;padding-bottom:54px;">
        <Header style="margin-top:-88px;position: relative;"></Header>
        <div style="min-height:100%;width:100%;backgroup:#fdfdfd;">                     
            <div class="container conColor" style="min-height:auto;" v-show="showLoading==false">
                <ul class="side_Gourp cleft">
                    <li class="sideItem" v-for="(item,key) in sideList" :key="key">
                        <div class="titles" :class="sideActive==item.id?'current':''" @click="getGroupClick(item)">
                            <span :class="sideActive==item.id?'cheks':''"></span>
                            {{item.name}}
                        </div>
                    </li>
                </ul>
                <!-- 产品应用 -->
                <div class="viewrig cright" style="min-height:auto;" v-show="sideActive==1">
                    <div class="pdg38">
                        <h2 class="pfg_title"><span class="titleName">产品应用</span></h2>
                    </div>
                    <ul class="listSide" style="min-height:480px;">
                        <li v-for="(v,k) in groupData1" :key="k">
                            <a :href="edp_url+'html/'+v.newsurl" target="_blank">
                                <div class="title cleft"><i class="gwicon lis"></i><span :title="v.title">{{v.title}}</span></div>
                                <div class="times cright">{{v.pubdate}}</div>
                            </a>
                        </li>
                    </ul>
                    <div class="ipages" :style="{'height':pageHeight+'px','line-height':pageHeight+'px'}" v-show="totalPage1>pageSize1">
                        <Page :current="currentPage1" :total="totalPage1" :page-size="pageSize1" @on-change="jumpPage1" />
                    </div>
                </div>
                <!-- 分析报告 -->
                <div class="viewrig cright" style="min-height:auto;" v-show="sideActive==2">
                    <div class="pdg38">
                        <h2 class="pfg_title"><span class="titleName">分析报告</span></h2>
                    </div>
                    <ul class="listSide" style="min-height:480px;">
                        <li v-for="(v,k) in groupData2" :key="k">
                            <a :href="edp_url+'html/'+v.newsurl" target="_blank">
                                <div class="title cleft"><i class="gwicon lis"></i><span :title="v.title">{{v.title}}</span></div>
                                <div class="times cright">{{v.pubdate}}</div>
                            </a>
                        </li>
                    </ul>
                    <div class="ipages" :style="{'height':pageHeight+'px','line-height':pageHeight+'px'}" v-show="totalPage2>pageSize2">
                        <Page :current="currentPage2" :total="totalPage2" :page-size="pageSize2" @on-change="jumpPage2"/>
                    </div>
                </div>

                <!-- 产品动态 -->
                <div class="viewrig cright" style="min-height:auto;" v-show="sideActive==4">
                    <div class="pdg38">
                        <h2 class="pfg_title"><span class="titleName">产品动态</span></h2>
                    </div>
                    <ul class="listSide" style="min-height:480px;">
                        <li v-for="(v,k) in groupData4" :key="k">
                            <a :href="edp_url+'html/'+v.newsurl" target="_blank">
                                <div class="title cleft"><i class="gwicon lis"></i><span :title="v.title">{{v.title}}</span></div>
                                <div class="times cright">{{v.pubdate}}</div>
                            </a>
                        </li>
                    </ul>
                    <div class="ipages" :style="{'height':pageHeight+'px','line-height':pageHeight+'px'}" v-show="totalPage4>pageSize4">
                        <Page :current="currentPage4" :total="totalPage4" :page-size="pageSize4" @on-change="jumpPage4"/>
                    </div>
                </div>
                
                <div class="clear"></div>
            </div> 
            <div class="clear"></div>
        </div> 
        <Footer></Footer>
        <div class="loading" v-show="showLoading==true">            
            <div class="loadingInner"></div>
        </div>
    </div>
</template>
<script>
import Header from './commons/Header.vue';
import Footer from './commons/Footer.vue';
import axios from 'axios';
import {
  config,
  olapUrl,
} from "../until/common.js";
export default {
	name:'Paper',
	components:{
        Header,
        Footer,
    },
    data() {
        return {
            edp_url:null,
            showLoading:false,
            Page_Size:10,
            sideActive:1,
            sideList:[
                {id:1,name:'产品应用'},
                {id:2,name:'分析报告'},
                // {id:3,name:'数据头条'},
                {id:4,name:'产品动态'},
                // {id:5,name:'论文引用'},
            ],
            sideActiveItem:5,//用于判断5国内和6国外期刊
            sideTab:[
                {id:5,name:'国内核心期刊'},
                {id:6,name:'外文期刊'},
            ],


            // 产品应用
            groupData1:[],
            currentPage1:1,
            totalPage1:0,
            pageSize1:0,
            //分析报告
            groupData2:[],
            currentPage2:1,
            totalPage2:0,
            pageSize2:0,

            //产品动态
            groupData4:[],
            currentPage4:1,
            totalPage4:0,
            pageSize4:0,
            //国内期刊引用
            groupData5:[],
            currentPage5:1,
            totalPage5:0,
            pageSize5:0,
            //外文期刊
            groupData6:[], 
            currentPage6:1,
            totalPage6:0,
            pageSize6:0,
            pageHeight:null,
        }
    },
    methods:{

        // 左侧菜单栏
        getGroupClick:function(item){
            this.sideActive = item.id;
            if(item.id==1){
                this.getList1(1);
            }else if(item.id==2){
                this.getList2(1);
            }else if(item.id==4){
                this.getList4(1);
            }
        },
        // 产品应用
        getList1(current){
            var that = this;
            that.showLoading = true;
            axios.get(config.baseUrl+'api/comgedata?sp=News_GetList&p=PageSize;PageIndex;newsitem&v=10;'+current+';11').then((res)=> {  
                that.groupData1 = res.data.result[0];
                that.currentPage1 = res.data.result[1][0].PageIndex;
                that.totalPage1 = res.data.result[1][0].Total;
                that.pageSize1 = res.data.result[1][0].PageSize;
                that.showLoading = false;
            }).catch(function(error) {
                window.console.log(error);
            });
        },
        jumpPage1(current){
            this.getList1(current);
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        },
        //分析报告
        getList2(current){
            var that = this;
            that.showLoading = true;
            axios.get(config.baseUrl+'api/comgedata?sp=News_GetList&p=PageSize;PageIndex;newsitem&v='+this.Page_Size+';'+current+';12').then((res)=> {  
                that.groupData2 = res.data.result[0];
                that.currentPage2 = res.data.result[1][0].PageIndex;
                that.totalPage2 = res.data.result[1][0].Total;
                that.pageSize2= res.data.result[1][0].PageSize;
                that.showLoading = false;
            }).catch(function(error) {
                window.console.log(error);
            });
        },
        jumpPage2(current){
            this.getList2(current);
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        },

        // 产品动态
        getList4(current){
            var that = this;
            that.showLoading = true;
            axios.get(config.baseUrl+'api/comgedata?sp=News_GetList&p=PageSize;PageIndex;newsitem&v='+this.Page_Size+';'+current+';13').then((res)=> {  
                that.groupData4 = res.data.result[0];
                that.currentPage4 = res.data.result[1][0].PageIndex;
                that.pageSize4 = res.data.result[1][0].PageSize;
                that.totalPage4 = res.data.result[1][0].Total;
                that.showLoading = false;
            }).catch(function(error) {
                window.console.log(error);
            });
        },
        jumpPage4(current){
            this.getList4(current);
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        },

        selectedProvinceObj:function(v){
            this.dateNameObj = v;
            if(this.sideActiveItem==5){
                this.getList5(1);
            }else{
                this.getList6(1);
            }
        },
       
    },
    mounted() {
        this.edp_url = olapUrl;//(http://edp.epsnet.com.cn/)
        document.onclick = () => {
            this.showList = {};
        }
        this.sideActive = this.$route.params.side;
        this.sideActiveItem = this.$route.params.id;
        if(this.sideActive==1){
            this.getList1(1);
        }else if(this.sideActive==2){
            this.getList2(1);
        }else if(this.sideActive==3){
            this.getList3(1);
        }else if(this.sideActive==4){
            this.getList4(1);
        }
        /* 
            计算一屏展示不出现滚动条
            top:88,title:70,foot:54,min:500
            88+70+54+500 = 712
        */
        var w_height = window.innerHeight;
        this.pageHeight = w_height-712;
    },
}
</script>
<style lang="scss">

</style>